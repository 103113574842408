import {
  SupplyFixWorkProgressStateTypes,
  SupplyFixWorkProgressActionTypes,
} from "./SupplyFixWorkProgress.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SupplyFixWorkProgressStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SupplyFixWorkProgressReducer = (
  state: SupplyFixWorkProgressStateTypes = INITIAL_STATE,
  action: any
): SupplyFixWorkProgressStateTypes => {
  switch (action.type) {
    case SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SupplyFixWorkProgressReducer;
