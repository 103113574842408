import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { Payroll2ActionTypes } from "./Payroll2.type";

export function* fetchAllPayroll2s(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/payroll2${query}`);

    yield put({
      type: Payroll2ActionTypes.FETCH_ALL_PAYROLL2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: Payroll2ActionTypes.FETCH_ALL_PAYROLL2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePayroll2s(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/payroll2/one${query}`);
    yield put({
      type: Payroll2ActionTypes.FETCH_ONE_PAYROLL2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: Payroll2ActionTypes.FETCH_ONE_PAYROLL2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPayroll2sAggregate(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/payroll2/aggregate${query}`
    );
    yield put({
      type: Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayroll2s() {
  yield takeLatest(Payroll2ActionTypes.FETCH_ALL_PAYROLL2, fetchAllPayroll2s);
}

export function* watcherFetchOnePayroll2s() {
  yield takeLatest(Payroll2ActionTypes.FETCH_ONE_PAYROLL2, fetchOnePayroll2s);
}

export function* watcherFetchPayroll2sAggregate() {
  yield takeLatest(
    Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE,
    fetchPayroll2sAggregate
  );
}
