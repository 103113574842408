import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type SupportLetter = {
  id: number;
  date: string;
  client: string;
  driver: string;
  location: string;
  plate_number: string;
};

export type SupportLetterStateTypes = {
  fetchAll: ApiCallState<SupportLetter[]>;
  fetchOne: ApiCallState<SupportLetter | {}>;
};

export const SupportLetterActionTypes = {
  FETCH_ALL_SUPPORT_LETTER: "FETCH_ALL_SUPPORT_LETTER",
  FETCH_ALL_SUPPORT_LETTER_RESET: "FETCH_ALL_SUPPORT_LETTER_RESET",
  FETCH_ALL_SUPPORT_LETTER_FAILURE: "FETCH_ALL_SUPPORT_LETTER_FAILURE",
  FETCH_ALL_SUPPORT_LETTER_SUCCESS: "FETCH_ALL_SUPPORT_LETTER_SUCCESS",

  FETCH_ONE_SUPPORT_LETTER: "FETCH_ONE_SUPPORT_LETTER",
  FETCH_ONE_SUPPORT_LETTER_RESET: "FETCH_ONE_SUPPORT_LETTER_RESET",
  FETCH_ONE_SUPPORT_LETTER_FAILURE: "FETCH_ONE_SUPPORT_LETTER_FAILURE",
  FETCH_ONE_SUPPORT_LETTER_SUCCESS: "FETCH_ONE_SUPPORT_LETTER_SUCCESS",
};
