
import { ApiCallState } from "../Utils";

export type PaymentCertificateRoad= {
  id: number;
  pid:number
  takeoff_id: number,
  timesing: number,
  length: number,
  width: number,
  height: number,
  total: number,
  approval: number,
  remark: string,
};

export type PaymentCertificateRoadStateTypes = {
  fetchAll: ApiCallState<PaymentCertificateRoad[]>;
  fetchOne: ApiCallState<PaymentCertificateRoad | {}>;
};

export const PaymentCertificateRoadActionTypes = {
  FETCH_ALL_PAYMENT_CERTIFICATE_ROAD: "FETCH_ALL_PAYMENT_CERTIFICATE_ROAD",
  FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_RESET: "FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_RESET",
  FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_FAILURE: "FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_FAILURE",
  FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_SUCCESS: "FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_SUCCESS",

  FETCH_ONE_PAYMENT_CERTIFICATE_ROAD: "FETCH_ONE_PAYMENT_CERTIFICATE_ROAD",
  FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_RESET: "FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_RESET",
  FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_FAILURE: "FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_FAILURE",
  FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_SUCCESS: "FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_SUCCESS",
};
