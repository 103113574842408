import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentMasterListActionTypes } from "./EquipmentMasterList.type";

export function* fetchAllEquipmentMasterLists(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query = Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-master-list?${query}`
    );
    yield put({
      type: EquipmentMasterListActionTypes.FETCH_ALL_EQUIPMENT_MASTER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentMasterListActionTypes.FETCH_ALL_EQUIPMENT_MASTER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentMasterLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-master-list/${action.payload}`
    );
    yield put({
      type: EquipmentMasterListActionTypes.FETCH_ONE_EQUIPMENT_MASTER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentMasterListActionTypes.FETCH_ONE_EQUIPMENT_MASTER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentMasterLists() {
  yield takeLatest(
    EquipmentMasterListActionTypes.FETCH_ALL_EQUIPMENT_MASTER_LIST,
    fetchAllEquipmentMasterLists
  );
}

export function* watcherFetchOneEquipmentMasterLists() {
  yield takeLatest(
    EquipmentMasterListActionTypes.FETCH_ONE_EQUIPMENT_MASTER_LIST,
    fetchOneEquipmentMasterLists
  );
}
