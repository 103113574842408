import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { HRAnalyticsActionTypes } from "./HRAnalytics.type";

export function* fetchAllHRAnalytics(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/hr-analytics?${query}`);
    yield put({
      type: HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneHRAnalytics(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/hr-analytics/${action.payload}`
    );
    yield put({
      type: HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllHRAnalytics() {
  yield takeLatest(
    HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS,
    fetchAllHRAnalytics
  );
}

export function* watcherFetchOneHRAnalytics() {
  yield takeLatest(
    HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS,
    fetchOneHRAnalytics
  );
}
