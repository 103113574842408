import { MasterScheduleUpdatedStateTypes, MasterScheduleUpdatedActionTypes } from "./MasterScheduleUpdated.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MasterScheduleUpdatedStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MasterScheduleUpdatedReducer = (
  state: MasterScheduleUpdatedStateTypes = INITIAL_STATE,
  action: any
): MasterScheduleUpdatedStateTypes => {
  switch (action.type) {
    case MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MasterScheduleUpdatedReducer;
