import { CheckListStateTypes, CheckListActionTypes } from "./CheckList.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CheckListStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchChecklistNumber: resetApiCallState(null),
};

const CheckListReducer = (
  state: CheckListStateTypes = INITIAL_STATE,
  action: any
): CheckListStateTypes => {
  switch (action.type) {
    case CheckListActionTypes.FETCH_ALL_CHECK_LIST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CheckListActionTypes.FETCH_ALL_CHECK_LIST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CheckListActionTypes.FETCH_ALL_CHECK_LIST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CheckListActionTypes.FETCH_ALL_CHECK_LIST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case CheckListActionTypes.FETCH_ONE_CHECK_LIST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CheckListActionTypes.FETCH_ONE_CHECK_LIST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CheckListActionTypes.FETCH_ONE_CHECK_LIST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CheckListActionTypes.FETCH_ONE_CHECK_LIST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------------------------

    case CheckListActionTypes.FETCH_CHECK_LIST_NUMBER:
      return {
        ...state,
        fetchChecklistNumber: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case CheckListActionTypes.FETCH_CHECK_LIST_NUMBER_RESET:
      return {
        ...state,
        fetchChecklistNumber: resetApiCallState(null),
      };
    case CheckListActionTypes.FETCH_CHECK_LIST_NUMBER_FAILURE:
      return {
        ...state,
        fetchChecklistNumber: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CheckListActionTypes.FETCH_CHECK_LIST_NUMBER_SUCCESS:
      return {
        ...state,
        fetchChecklistNumber: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CheckListReducer;
