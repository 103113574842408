import { Payroll2StateTypes, Payroll2ActionTypes } from "./Payroll2.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: Payroll2StateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchAggregate: resetApiCallState({}),
};

const Payroll2Reducer = (
  state: Payroll2StateTypes = INITIAL_STATE,
  action: any
): Payroll2StateTypes => {
  switch (action.type) {
    case Payroll2ActionTypes.FETCH_ALL_PAYROLL2:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case Payroll2ActionTypes.FETCH_ALL_PAYROLL2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case Payroll2ActionTypes.FETCH_ALL_PAYROLL2_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case Payroll2ActionTypes.FETCH_ALL_PAYROLL2_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case Payroll2ActionTypes.FETCH_ONE_PAYROLL2:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case Payroll2ActionTypes.FETCH_ONE_PAYROLL2_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case Payroll2ActionTypes.FETCH_ONE_PAYROLL2_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case Payroll2ActionTypes.FETCH_ONE_PAYROLL2_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE:
      return {
        ...state,
        fetchAggregate: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE_RESET:
      return {
        ...state,
        fetchAggregate: resetApiCallState({}),
      };
    case Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE_FAILURE:
      return {
        ...state,
        fetchAggregate: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case Payroll2ActionTypes.FETCH_PAYROLL2_AGGREGATE_SUCCESS:
      return {
        ...state,
        fetchAggregate: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default Payroll2Reducer;
