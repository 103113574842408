import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CompanyLoanActionTypes } from "./CompanyLoan.type";

export function* fetchAllCompanyLoans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/company-loan`);
    yield put({
      type: CompanyLoanActionTypes.FETCH_ALL_COMPANY_LOAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CompanyLoanActionTypes.FETCH_ALL_COMPANY_LOAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCompanyLoans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/company-loan/${action.payload}`
    );
    yield put({
      type: CompanyLoanActionTypes.FETCH_ONE_COMPANY_LOAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CompanyLoanActionTypes.FETCH_ONE_COMPANY_LOAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCompanyLoans() {
  yield takeLatest(
    CompanyLoanActionTypes.FETCH_ALL_COMPANY_LOAN,
    fetchAllCompanyLoans
  );
}

export function* watcherFetchOneCompanyLoans() {
  yield takeLatest(
    CompanyLoanActionTypes.FETCH_ONE_COMPANY_LOAN,
    fetchOneCompanyLoans
  );
}
