import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CheckListActionTypes } from "./CheckList.type";

export function* fetchAllCheckLists(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/check-list`);
    yield put({
      type: CheckListActionTypes.FETCH_ALL_CHECK_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CheckListActionTypes.FETCH_ALL_CHECK_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCheckLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/check-list/${action.payload}`
    );
    yield put({
      type: CheckListActionTypes.FETCH_ONE_CHECK_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CheckListActionTypes.FETCH_ONE_CHECK_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchChecklistNumber(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/check-list/checklist-number`
    );
    yield put({
      type: CheckListActionTypes.FETCH_CHECK_LIST_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CheckListActionTypes.FETCH_CHECK_LIST_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCheckLists() {
  yield takeLatest(
    CheckListActionTypes.FETCH_ALL_CHECK_LIST,
    fetchAllCheckLists
  );
}

export function* watcherFetchOneCheckLists() {
  yield takeLatest(
    CheckListActionTypes.FETCH_ONE_CHECK_LIST,
    fetchOneCheckLists
  );
}

export function* watcherFetchChecklistNumbers() {
  yield takeLatest(
    CheckListActionTypes.FETCH_CHECK_LIST_NUMBER,
    fetchChecklistNumber
  );
}
