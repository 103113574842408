import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentCertificateRoadActionTypes } from "./PaymentCertificateRoad.type";

export function* fetchAllPaymentCertificateRoads(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment_certificate_road`);
    yield put({
      type: PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentCertificateRoads(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment_certificate_road/${action.payload}`
    );
    yield put({
      type: PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentCertificateRoads() {
  yield takeLatest(PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD, fetchAllPaymentCertificateRoads);
}

export function* watcherFetchOnePaymentCertificateRoads() {
  yield takeLatest(PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD, fetchOnePaymentCertificateRoads);
}
