import { FC, useState } from "react";
import Popover from "antd/lib/popover";
import { useHistory } from "react-router-dom";
import { getInitials, getUserData, logout } from "../../../utilities/utilities";
import { RouteConstants } from "../../../router/Constants";
import Button from "antd/lib/button";
import Divider from "antd/lib/divider";
import Avatar from "antd/lib/avatar";
import { BookOutlined } from "@ant-design/icons";
import Logo from "../../../Images/condigital_icon.png";
import { UpCircleOutlined } from "@ant-design/icons";
import HeaderMenuComponent from "./HeaderMenu.component";

import { connect } from "react-redux";
import { RestartTour } from "../../../redux/Tour/Tour.action";
import { BUILD, BuildType } from "../../../constants/Constants";
import LogComponent from "../../../components/Log/Log.component";
import SettingsComponent from "../../../components/Settings/Settings.component";
import WelcomeModal from "../../../constants/WelcomeModal";
import TourModal from "../../../components/Tour/TourModalComponent";
const HeaderComponent: FC<{ onCollapse: Function; restart: Function }> = ({
  onCollapse,
  restart,
}) => {
  const history = useHistory();

  const [data] = useState(getUserData());
  const [tour_visibility, setTourVisibility] = useState(false);

  return (
    <div className="limit-container">
      <WelcomeModal setTourVisibility={setTourVisibility} />
      <div className="row">
        <div className="header-wrapper">
          <div className="header-breadcrumb">
            {/* <Button
              type="link"
              className="collapse-btn"
              onClick={() => onCollapse()}
            >
              <MenuFoldOutlined />
            </Button>
            <BreadcrumbComponent /> */}
            <img src={Logo} alt="Logo" />
            <span
              style={{
                lineHeight: "40px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              ConDigital
            </span>
          </div>
          <HeaderMenuComponent />
          <div className="header-icons">
            <LogComponent />
            {/* <Button
              icon={<BookOutlined />}
              type="link"
              className="mr-1"
              onClick={() => setTourVisibility(true)}
            ></Button> */}
            {/* <TourModal
              isModalVisible={tour_visibility}
              setIsModalVisible={setTourVisibility}
            /> */}
            <Popover
              overlayClassName="header-popover"
              placement="bottomRight"
              content={
                <>
                  <h6 className="con-user">{data ? data.full_name : null}</h6>
                  <h6
                    style={{
                      backgroundColor: "#6a7a9c",
                      display: "inline",
                      color: "white",

                      padding: "6px",
                      borderRadius: "8px",
                      fontSize: "10px",
                    }}
                  >
                    {BUILD === BuildType.ENTERPRISE_PROJECT
                      ? BuildType.PROJECT
                      : BUILD}
                  </h6>
                  {BUILD === BuildType.PROJECT ||
                  BUILD === BuildType.ENTERPRISE_PROJECT ? (
                    <a target="blank" href="https://condigitaleth.com/demo/">
                      <Button
                        className="d-block"
                        type="link"
                        size="middle"
                        icon={
                          <UpCircleOutlined
                            style={{ verticalAlign: "text-bottom" }}
                          />
                        }
                        style={{ paddingLeft: "0px" }}
                      >
                        Upgrade Package
                      </Button>
                    </a>
                  ) : null}

                  {/* <Button type="link" icon={<SettingOutlined/>} style={{paddingLeft:"0px",marginTop:"5px"}} className="d-block">Setting</Button> */}
                  <SettingsComponent />

                  {/* <br></br> */}
                  <Divider style={{ marginBottom: "13px" }} />
                  <Button
                    style={{
                      paddingLeft: "0px",
                      marginTop: "0px",
                      paddingTop: "0px",
                    }}
                    className="btn btn-link"
                    onClick={() => {
                      logout();
                      history.push(RouteConstants.LOGIN);
                    }}
                  >
                    Sign Out
                  </Button>
                </>
              }
              trigger="hover"
            >
              <Avatar style={{ color: "#fff", backgroundColor: "#0033a1" }}>
                {getInitials(data.full_name)}
              </Avatar>
              {/*
              <div className="col flex-row">
                <div className="header-avatar">
                  {/* <h5>{getInitials(data.full_name)}</h5>
                </div>

                {/* <span className="con-user ml-2">{data ? data.name : null}</span>
              </div> */}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  restart: (action: any) => dispatch(RestartTour(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
