import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Payroll2 = {
  id: number;
  user_id: number;

  date: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  payroll2_items: Payroll2Item[];
};

export type Payroll2Item = {
  id: number;
  payroll2_id: number;
  staff_id: number;

  basic_salary: number;
  absent: number;
  overtime: number;
  absent_penalty: number;
  peridium_allowance: number;
  responsibility_allowance: number;
  taxable_allowance: number;
  telephone_allowance: number;
  house_allowance: number;
  transport_allowance: number;
  gross_pay: number;
  taxable_income: number;
  income_tax: number;
  pension_11: number;
  pension_7: number;
  deduction: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  staff: Staff;
};

export type Payroll2Aggregate = {
  net_payment: number;
  income_tax: number;
  pension: number;
};

export type Payroll2StateTypes = {
  fetchAll: ApiCallState<Payroll2[]>;
  fetchOne: ApiCallState<Payroll2 | {}>;
  fetchAggregate: ApiCallState<Payroll2Aggregate | {}>;
};

export const Payroll2ActionTypes = {
  FETCH_ALL_PAYROLL2: "FETCH_ALL_PAYROLL2",
  FETCH_ALL_PAYROLL2_RESET: "FETCH_ALL_PAYROLL2_RESET",
  FETCH_ALL_PAYROLL2_FAILURE: "FETCH_ALL_PAYROLL2_FAILURE",
  FETCH_ALL_PAYROLL2_SUCCESS: "FETCH_ALL_PAYROLL2_SUCCESS",

  FETCH_ONE_PAYROLL2: "FETCH_ONE_PAYROLL2",
  FETCH_ONE_PAYROLL2_RESET: "FETCH_ONE_PAYROLL2_RESET",
  FETCH_ONE_PAYROLL2_FAILURE: "FETCH_ONE_PAYROLL2_FAILURE",
  FETCH_ONE_PAYROLL2_SUCCESS: "FETCH_ONE_PAYROLL2_SUCCESS",

  FETCH_PAYROLL2_AGGREGATE: "FETCH_PAYROLL2_AGGREGATE",
  FETCH_PAYROLL2_AGGREGATE_RESET: "FETCH_PAYROLL2_AGGREGATE_RESET",
  FETCH_PAYROLL2_AGGREGATE_FAILURE: "FETCH_PAYROLL2_AGGREGATE_FAILURE",
  FETCH_PAYROLL2_AGGREGATE_SUCCESS: "FETCH_PAYROLL2_AGGREGATE_SUCCESS",
};
