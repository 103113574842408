import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SupplyFixWorkProgressActionTypes } from "./SupplyFixWorkProgress.type";

export function* fetchAllSupplyFixWorkProgress(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/supply-fix-work-progress?supply_fix_id=${action.payload?.supply_fix_id}&date=${action.payload?.date}`
    );
    yield put({
      type: SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSupplyFixWorkProgress(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/supply-fix-work-progress/${action.payload}`
    );
    yield put({
      type: SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSupplyFixWorkProgress() {
  yield takeLatest(
    SupplyFixWorkProgressActionTypes.FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS,
    fetchAllSupplyFixWorkProgress
  );
}

export function* watcherFetchOneSupplyFixWorkProgress() {
  yield takeLatest(
    SupplyFixWorkProgressActionTypes.FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS,
    fetchOneSupplyFixWorkProgress
  );
}
