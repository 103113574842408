import React, { useState } from "react";
// import { LoadingOutlined } from "@ant-design/icons";
import Logo from "../../../Images/LogoHorizontal.svg";
// import { login, sendConfirmation } from "./Login.util";
// import { initAxios, saveUserData } from "../../utilities/utilities";
// import { useHistory } from "react-router-dom";

// import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
// import Alert from "antd/lib/alert";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
// import AboutComponent from "./components/About/About.component";
// import TermComponent from "./components/Terms/Tems.component";
// import Password from "antd/lib/input/Password";
// import { OpenNotification } from "../Notification/Notification.component";
// import { NotificationType } from "../../constants/constants";
// import { Spin } from "antd";
import { OpenNotification } from "../../common/Notification/Notification.component";
import { sendEmailResetLink } from "./resetEmail.util";
import { NotificationType } from "../../../constants/Constants";

export default function ResetEmail() {
  const [loading, setLoading] = useState(false);
  const onFinish = (values: { email: string }) => {
    setLoading(true);
    sendEmailResetLink(values.email)
      .then((res) => {
        setLoading(false);
        OpenNotification(
          NotificationType.SUCCESS,
          "Reset link sent!",
          "Please check your email to reset password"
        );
      })
      .catch((err) => {
        setLoading(false);
        OpenNotification(NotificationType.ERROR, "Failed", "Error Occured");
      });
  };
  return (
    <div className="container-fluid">
      <nav>
        <div className="container">
          <img src={Logo} alt="Logo" className="LoginLogo mx-auto d-block" />
        </div>
      </nav>
      <div className="mtop-5 col-lg-7 col-sm-9 mx-auto">
        <h4 className="mb-4 mt-4 font-weight-bold text-center h4-20">
          Reset account
        </h4>
        <Card className="col-lg-7 col-sm-10 mx-auto lc">
          <Form
            name="basic"
            layout={"vertical"}
            onFinish={onFinish}
            initialValues={{ email: localStorage.getItem("email-to-send") }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button
              htmlType="submit"
              className="mt-4"
              type="primary"
              block
              loading={loading}
            >
              Submit
            </Button>
          </Form>
        </Card>
        <Row className="mx-auto mt-3">
          <h5 className="text-center col-lg primary small">ConDigital, Inc.</h5>
        </Row>
      </div>
    </div>
  );
}
