import { PayrollStateTypes, PayrollActionTypes } from "./Payroll.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PayrollStateTypes = {
  fetchAllLabour: resetApiCallState([]),
  fetchAllStaff: resetApiCallState([]),
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchStaffHistory: resetApiCallState([]),
  fetchLabourHistory: resetApiCallState([]),
};

const PayrollReducer = (
  state: PayrollStateTypes = INITIAL_STATE,
  action: any
): PayrollStateTypes => {
  switch (action.type) {
    case PayrollActionTypes.FETCH_ALL_PAYROLL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL:
      return {
        ...state,
        fetchAllStaff: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL_RESET:
      return {
        ...state,
        fetchAllStaff: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL_FAILURE:
      return {
        ...state,
        fetchAllStaff: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        fetchAllStaff: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL:
      return {
        ...state,
        fetchAllLabour: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL_RESET:
      return {
        ...state,
        fetchAllLabour: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL_FAILURE:
      return {
        ...state,
        fetchAllLabour: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL_SUCCESS:
      return {
        ...state,
        fetchAllLabour: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY:
      return {
        ...state,
        fetchStaffHistory: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY_RESET:
      return {
        ...state,
        fetchStaffHistory: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY_FAILURE:
      return {
        ...state,
        fetchStaffHistory: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY_SUCCESS:
      return {
        ...state,
        fetchStaffHistory: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY:
      return {
        ...state,
        fetchLabourHistory: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY_RESET:
      return {
        ...state,
        fetchLabourHistory: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY_FAILURE:
      return {
        ...state,
        fetchLabourHistory: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY_SUCCESS:
      return {
        ...state,
        fetchLabourHistory: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PayrollReducer;
