import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SupportLetterActionTypes } from "./SupportLetter.type";

export function* fetchAllSupportLetters(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/support-letter`);
    yield put({
      type: SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSupportLetters(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/support-letter/${action.payload}`
    );
    yield put({
      type: SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSupportLetters() {
  yield takeLatest(
    SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER,
    fetchAllSupportLetters
  );
}

export function* watcherFetchOneSupportLetters() {
  yield takeLatest(
    SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER,
    fetchOneSupportLetters
  );
}
