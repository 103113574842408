import {
  HRAnalyticsStateTypes,
  HRAnalyticsActionTypes,
} from "./HRAnalytics.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: HRAnalyticsStateTypes = {
  fetchAll: resetApiCallState(null),
  fetchOne: resetApiCallState(null),
};

const HRAnalyticsReducer = (
  state: HRAnalyticsStateTypes = INITIAL_STATE,
  action: any
): HRAnalyticsStateTypes => {
  switch (action.type) {
    case HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState(null),
      };
    case HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HRAnalyticsActionTypes.FETCH_ALL_HR_ANALYTICS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HRAnalyticsActionTypes.FETCH_ONE_HR_ANALYTICS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default HRAnalyticsReducer;
