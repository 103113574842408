import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { ApiCallState } from "../Utils";

export type PurchaseBilling = {
  id: number;
  purchase_order_id: number;
  date: string;
  purchase_for: string;
  pad_ref_number: string;
  purchase_place: string;
  purchase_billing_items: PurchaseBillingItem[];
  purchase_order: PurchaseOrder;
  purchase_billing_statuses: PurchaseBillingStatus[];
};

export type PurchaseBillingItem = {
  invoice_number: string;
  description: string;
  invoice_date: string;
  due_date: string;
  invoice_amount: number;
  key: number;
};

export type PurchaseBillingStatus = {
  purchase_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PurchaseBillingStateTypes = {
  fetchAll: ApiCallState<PurchaseBilling[]>;
};

export const PurchaseBillingActionTypes = {
  FETCH_ALL_PURCHASE_BILLING: "FETCH_ALL_PURCHASE_BILLING",
  FETCH_ALL_PURCHASE_BILLING_RESET: "FETCH_ALL_PURCHASE_BILLING_RESET",
  FETCH_ALL_PURCHASE_BILLING_FAILURE: "FETCH_ALL_PURCHASE_BILLING_FAILURE",
  FETCH_ALL_PURCHASE_BILLING_SUCCESS: "FETCH_ALL_PURCHASE_BILLING_SUCCESS",
};
