import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StoreActionTypes } from "./Store.type";

export function* fetchAllStores(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/store`);
    yield put({
      type: StoreActionTypes.FETCH_ALL_STORE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreActionTypes.FETCH_ALL_STORE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStores(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/store/${action.payload}`);
    yield put({
      type: StoreActionTypes.FETCH_ONE_STORE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreActionTypes.FETCH_ONE_STORE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStores() {
  yield takeLatest(StoreActionTypes.FETCH_ALL_STORE, fetchAllStores);
}

export function* watcherFetchOneStores() {
  yield takeLatest(StoreActionTypes.FETCH_ONE_STORE, fetchOneStores);
}
