import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LoanRequestActionTypes } from "./LoanRequest.type";

export function* fetchAllLoanRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/loan-request`);
    yield put({
      type: LoanRequestActionTypes.FETCH_ALL_LOAN_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanRequestActionTypes.FETCH_ALL_LOAN_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLoanRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/loan-request/${action.payload}`
    );
    yield put({
      type: LoanRequestActionTypes.FETCH_ONE_LOAN_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanRequestActionTypes.FETCH_ONE_LOAN_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLoanRequests() {
  yield takeLatest(
    LoanRequestActionTypes.FETCH_ALL_LOAN_REQUEST,
    fetchAllLoanRequests
  );
}

export function* watcherFetchOneLoanRequests() {
  yield takeLatest(
    LoanRequestActionTypes.FETCH_ONE_LOAN_REQUEST,
    fetchOneLoanRequests
  );
}
