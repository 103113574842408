import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type StaffPenalty = {
  id: number;
  user_id: number;
  staff_id: number;
  date: string;
  amount: number;
  reason: string;

  staff: Staff;
};

export type StaffPenaltyStateTypes = {
  fetchAll: ApiCallState<StaffPenalty[]>;
  fetchOne: ApiCallState<StaffPenalty | {}>;
};

export const StaffPenaltyActionTypes = {
  FETCH_ALL_STAFF_PENALTY: "FETCH_ALL_STAFF_PENALTY",
  FETCH_ALL_STAFF_PENALTY_RESET: "FETCH_ALL_STAFF_PENALTY_RESET",
  FETCH_ALL_STAFF_PENALTY_FAILURE: "FETCH_ALL_STAFF_PENALTY_FAILURE",
  FETCH_ALL_STAFF_PENALTY_SUCCESS: "FETCH_ALL_STAFF_PENALTY_SUCCESS",

  FETCH_ONE_STAFF_PENALTY: "FETCH_ONE_STAFF_PENALTY",
  FETCH_ONE_STAFF_PENALTY_RESET: "FETCH_ONE_STAFF_PENALTY_RESET",
  FETCH_ONE_STAFF_PENALTY_FAILURE: "FETCH_ONE_STAFF_PENALTY_FAILURE",
  FETCH_ONE_STAFF_PENALTY_SUCCESS: "FETCH_ONE_STAFF_PENALTY_SUCCESS",
};
