import { Card } from "antd/lib";
import { MailFilled } from "@ant-design/icons";

export function EmailConfirmation() {
  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#f5f8fd", height: "100vh" }}
    >
      <div className="mtop-5 col-lg-7 col-sm-9 mx-auto">
        <div className="col-lg-7 col-sm-9 mx-auto mt-0 pt-1">
          <Card>
            <MailFilled
              style={{ color: "#0033a1", fontSize: "60px" }}
              className="mx-auto d-block mb-4"
            />
            <h3 className="text-center">Email Confirmation</h3>
            <p
              style={{
                textAlign: "center",
                color: "#585B72",
                fontFamily: "Campton-Book",
              }}
            >
              We have sent an email to{" "}
              <span className="email-confirm">
                {localStorage.getItem("email-to-send")}
              </span>{" "}
              to confirm <br></br>
              the validity of your email address. After receiving the email
              follow <br></br>
              the link provided to complete your registration.
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}
