import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PurchaseOrderActionTypes } from "./PurchaseOrder.type";

export function* fetchAllPurchaseOrders(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/purchase-order`);
    yield put({
      type: PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePurchaseOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/purchase-order/${action.payload}`
    );
    yield put({
      type: PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPurchaseOrders() {
  yield takeLatest(
    PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER,
    fetchAllPurchaseOrders
  );
}

export function* watcherFetchOnePurchaseOrders() {
  yield takeLatest(
    PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER,
    fetchOnePurchaseOrders
  );
}
