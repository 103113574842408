import { PaymentCertificateRoadStateTypes, PaymentCertificateRoadActionTypes } from "./PaymentCertificateRoad.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PaymentCertificateRoadStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PaymentCertificateRoadReducer = (
  state: PaymentCertificateRoadStateTypes = INITIAL_STATE,
  action: any
): PaymentCertificateRoadStateTypes => {
  switch (action.type) {
    case PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PaymentCertificateRoadActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_ROAD_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PaymentCertificateRoadActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_ROAD_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PaymentCertificateRoadReducer;
