import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ResignationActionTypes } from "./Resignation.type";

export function* fetchAllResignations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/resignation`);
    yield put({
      type: ResignationActionTypes.FETCH_ALL_RESIGNATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ResignationActionTypes.FETCH_ALL_RESIGNATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneResignations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/resignation/${action.payload}`
    );
    yield put({
      type: ResignationActionTypes.FETCH_ONE_RESIGNATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ResignationActionTypes.FETCH_ONE_RESIGNATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllResignations() {
  yield takeLatest(
    ResignationActionTypes.FETCH_ALL_RESIGNATION,
    fetchAllResignations
  );
}

export function* watcherFetchOneResignations() {
  yield takeLatest(
    ResignationActionTypes.FETCH_ONE_RESIGNATION,
    fetchOneResignations
  );
}
