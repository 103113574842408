import { ApiCallState } from "../Utils";

export type HRAnalytics = {
  id: number;

  employment_date: string;
  termination_date: string;
  job_description: string;
  name: string;
  photo: string;
  employment_birth_date: string;
  no_children: number;
  no_promotions: number;
  marital_status: string;

  // -----

  salary_paid: number;
  total_loan: number;
  outstanding_loan: number;

  // -----

  attendance_total: number;
  on_time_percentage: number;
  late_percentage: number;
  absent_percentage: number;
  leave_percentage: number;
  attendance_overall: number;

  attendance_total_Q1: number;
  on_time_percentage_Q1: number;
  late_percentage_Q1: number;
  absent_percentage_Q1: number;
  leave_percentage_Q1: number;
  attendance_overall_Q1: number;

  attendance_total_Q2: number;
  on_time_percentage_Q2: number;
  late_percentage_Q2: number;
  absent_percentage_Q2: number;
  leave_percentage_Q2: number;
  attendance_overall_Q2: number;

  attendance_total_Q3: number;
  on_time_percentage_Q3: number;
  late_percentage_Q3: number;
  absent_percentage_Q3: number;
  leave_percentage_Q3: number;
  attendance_overall_Q3: number;

  total_leave_days: number; // this year

  // -----

  tasks_given: number;
  tasks_completed: number;
  tasks_expired: number;
  tasks_performance: number;

  tasks_given_Q1: number;
  tasks_completed_Q1: number;
  tasks_expired_Q1: number;
  tasks_performance_Q1: number;

  tasks_given_Q2: number;
  tasks_completed_Q2: number;
  tasks_expired_Q2: number;
  tasks_performance_Q2: number;

  tasks_given_Q3: number;
  tasks_completed_Q3: number;
  tasks_expired_Q3: number;
  tasks_performance_Q3: number;

  // -----

  total_appreciations: number;
  total_disciplines: number;
  discipline_percentage: number;

  total_appreciations_Q1: number;
  total_disciplines_Q1: number;
  discipline_percentage_Q1: number;

  total_appreciations_Q2: number;
  total_disciplines_Q2: number;
  discipline_percentage_Q2: number;

  total_appreciations_Q3: number;
  total_disciplines_Q3: number;
  discipline_percentage_Q3: number;

  // -----
};

export type HRAnalyticsStateTypes = {
  fetchAll: ApiCallState<HRAnalytics | {}>;
  fetchOne: ApiCallState<HRAnalytics | {}>;
};

export const HRAnalyticsActionTypes = {
  FETCH_ALL_HR_ANALYTICS: "FETCH_ALL_HR_ANALYTICS",
  FETCH_ALL_HR_ANALYTICS_RESET: "FETCH_ALL_HR_ANALYTICS_RESET",
  FETCH_ALL_HR_ANALYTICS_FAILURE: "FETCH_ALL_HR_ANALYTICS_FAILURE",
  FETCH_ALL_HR_ANALYTICS_SUCCESS: "FETCH_ALL_HR_ANALYTICS_SUCCESS",

  FETCH_ONE_HR_ANALYTICS: "FETCH_ONE_HR_ANALYTICS",
  FETCH_ONE_HR_ANALYTICS_RESET: "FETCH_ONE_HR_ANALYTICS_RESET",
  FETCH_ONE_HR_ANALYTICS_FAILURE: "FETCH_ONE_HR_ANALYTICS_FAILURE",
  FETCH_ONE_HR_ANALYTICS_SUCCESS: "FETCH_ONE_HR_ANALYTICS_SUCCESS",
};
