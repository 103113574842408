import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { authHeader } from "../../utilities/utilities";

import { API_BASE_URI } from "../ApiCall";
import { PayrollActionTypes } from "./Payroll.type";

export function* fetchAllLabourPayrolls(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/labour?date=${action.payload?.date}&project_id=${action.payload?.project_id}&is_report=${action.payload.is_report}`
    );
    yield put({
      type: PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLabourPayrolls() {
  yield takeLatest(
    PayrollActionTypes.FETCH_ALL_LABOUR_PAYROLL,
    fetchAllLabourPayrolls
  );
}

export function* fetchAllStaffPayrolls(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/staff?date=${action.payload.date}&is_report=${action.payload.is_report}&project_id=${action.payload.project_id}`
    );
    yield put({
      type: PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffPayrolls() {
  yield takeLatest(
    PayrollActionTypes.FETCH_ALL_STAFF_PAYROLL,
    fetchAllStaffPayrolls
  );
}

export function* fetchAllPayrolls(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll?date=${action.payload?.date}&type=${action.payload?.type}`
    );
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayrolls() {
  yield takeLatest(PayrollActionTypes.FETCH_ALL_PAYROLL, fetchAllPayrolls);
}

export function* fetchAllPayrollReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/report?date=${action.payload?.date}`,
      authHeader()
    );
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayrollReport() {
  yield takeLatest(
    PayrollActionTypes.FETCH_ALL_PAYROLL_REPORT,
    fetchAllPayrollReport
  );
}

export function* fetchStaffHistory(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/staff/history?start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`,
      authHeader()
    );
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayrollStaffHistory() {
  yield takeLatest(
    PayrollActionTypes.FETCH_ALL_PAYROLL_STAFF_HISTORY,
    fetchStaffHistory
  );
}

export function* fetchLabourHistory(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/labour/history?start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`,
      authHeader()
    );
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayrollLabourHistory() {
  yield takeLatest(
    PayrollActionTypes.FETCH_ALL_PAYROLL_LABOUR_HISTORY,
    fetchLabourHistory
  );
}
