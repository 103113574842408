import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { checkerCall } from "./checker.util";
import { useHistory } from "react-router-dom";
import { saveUserData } from "../../../utilities/utilities";
import { OpenNotification } from "../../common/Notification/Notification.component";
import { NotificationType } from "../../../constants/Constants";
import { RouteConstants } from "../../../router/Constants";

export default function CheckerComponent() {
  const param: { hash: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    checkerCall(param?.hash)
      .then((res) => {
        saveUserData(res.data);
        history.push(RouteConstants.DASHBOARDS);
      })
      .catch((err) => {
        OpenNotification(
          NotificationType.ERROR,
          "Failed",
          "User could not be confirmed, try again"
        );
        setInterval(() => {
          history.push("/confirmation");
        }, 2000);
      });
  }, [history, param]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" />
    </div>
  );
}
