import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type MonthSubcontractorSchedule= {
    project_id: number | null;
    id: number | null;
    item:number;
    description: string;
    date: Moment;
    rate: number;
    unit:string;
    quantity:number;
    amount:number;
    output:number;
    week1: number;
    week2: number;
    week3: number;
    week4: number;
};

export type MonthSubcontractorScheduleStateTypes = {
  fetchAll: ApiCallState<MonthSubcontractorSchedule[]>;
  fetchOne: ApiCallState<MonthSubcontractorSchedule | {}>;
};

export type MonthSubcontractorScheduleActionType = {
    project_id: number;
    date: string;
};

export const MonthSubcontractorScheduleActionTypes = {
  FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE: "FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE",
  FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE_RESET: "FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE_RESET",
  FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE_FAILURE: "FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE_FAILURE",
  FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE_SUCCESS: "FETCH_ALL_MONTH_SUBCONTRACTOR_SCHEDULE_SUCCESS",

  FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE: "FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE",
  FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE_RESET: "FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE_RESET",
  FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE_FAILURE: "FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE_FAILURE",
  FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE_SUCCESS: "FETCH_ONE_MONTH_SUBCONTRACTOR_SCHEDULE_SUCCESS",
};
