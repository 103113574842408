import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LoanActionTypes } from "./Loan.type";

export function* fetchAllLoans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/loan`);
    yield put({
      type: LoanActionTypes.FETCH_ALL_LOAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanActionTypes.FETCH_ALL_LOAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchLoanStatuses(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/loan/status`);
    yield put({
      type: LoanActionTypes.FETCH_LOAN_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanActionTypes.FETCH_LOAN_STATUS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLoans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/loan/${action.payload}`);
    yield put({
      type: LoanActionTypes.FETCH_ONE_LOAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanActionTypes.FETCH_ONE_LOAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLoans() {
  yield takeLatest(LoanActionTypes.FETCH_ALL_LOAN, fetchAllLoans);
}

export function* watcherFetchLoanStatuses() {
  yield takeLatest(LoanActionTypes.FETCH_LOAN_STATUS, fetchLoanStatuses);
}

export function* watcherFetchOneLoans() {
  yield takeLatest(LoanActionTypes.FETCH_ONE_LOAN, fetchOneLoans);
}
