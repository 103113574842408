import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PromotionActionTypes } from "./Promotion.type";

export function* fetchAllPromotions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/promotion`);
    yield put({
      type: PromotionActionTypes.FETCH_ALL_PROMOTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PromotionActionTypes.FETCH_ALL_PROMOTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePromotions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/promotion/${action.payload}`
    );
    yield put({
      type: PromotionActionTypes.FETCH_ONE_PROMOTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PromotionActionTypes.FETCH_ONE_PROMOTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPromotions() {
  yield takeLatest(
    PromotionActionTypes.FETCH_ALL_PROMOTION,
    fetchAllPromotions
  );
}

export function* watcherFetchOnePromotions() {
  yield takeLatest(
    PromotionActionTypes.FETCH_ONE_PROMOTION,
    fetchOnePromotions
  );
}
