import { ApiCallState } from "../Utils";

export type CompanyLoan = {
  id: number;

  user_id: number;
  bank_account_id: number;

  description: string;
  amount: number;
  duration: number;
  loan_date: string;
  type: string;
  lender: string;
  recipient: string;

  payments: { id: number; date: string; amount: number }[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CompanyLoanStateTypes = {
  fetchAll: ApiCallState<CompanyLoan[]>;
  fetchOne: ApiCallState<CompanyLoan | {}>;
};

export const CompanyLoanActionTypes = {
  FETCH_ALL_COMPANY_LOAN: "FETCH_ALL_COMPANY_LOAN",
  FETCH_ALL_COMPANY_LOAN_RESET: "FETCH_ALL_COMPANY_LOAN_RESET",
  FETCH_ALL_COMPANY_LOAN_FAILURE: "FETCH_ALL_COMPANY_LOAN_FAILURE",
  FETCH_ALL_COMPANY_LOAN_SUCCESS: "FETCH_ALL_COMPANY_LOAN_SUCCESS",

  FETCH_ONE_COMPANY_LOAN: "FETCH_ONE_COMPANY_LOAN",
  FETCH_ONE_COMPANY_LOAN_RESET: "FETCH_ONE_COMPANY_LOAN_RESET",
  FETCH_ONE_COMPANY_LOAN_FAILURE: "FETCH_ONE_COMPANY_LOAN_FAILURE",
  FETCH_ONE_COMPANY_LOAN_SUCCESS: "FETCH_ONE_COMPANY_LOAN_SUCCESS",
};
