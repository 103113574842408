import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffPenaltyActionTypes } from "./StaffPenalty.type";

export function* fetchAllStaffPenalties(action: any): any {
  try {
    let keys: any[] = Object.keys(action?.payload);

    let assigns: any[] = keys.map(
      (key: any) => `${key}=${action?.payload[key]}`
    );
    let query: string = assigns.join("&");

    const response = yield axios.get(`${API_BASE_URI}/staff-penalty?${query}`);
    yield put({
      type: StaffPenaltyActionTypes.FETCH_ALL_STAFF_PENALTY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffPenaltyActionTypes.FETCH_ALL_STAFF_PENALTY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffPenalties(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-penalty/${action.payload}`
    );
    yield put({
      type: StaffPenaltyActionTypes.FETCH_ONE_STAFF_PENALTY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffPenaltyActionTypes.FETCH_ONE_STAFF_PENALTY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffPenalties() {
  yield takeLatest(
    StaffPenaltyActionTypes.FETCH_ALL_STAFF_PENALTY,
    fetchAllStaffPenalties
  );
}

export function* watcherFetchOneStaffPenalties() {
  yield takeLatest(
    StaffPenaltyActionTypes.FETCH_ONE_STAFF_PENALTY,
    fetchOneStaffPenalties
  );
}
