import { ApiCallState } from "../Utils";

export type SupplyFixContract = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  amount: number;
  date: string;
  date_of_agreement: string;
  payment_schedule: string;
  advance_amount: number;
  advance_date: string;
  status: string;
  remark: string;
  supply_fix_id: number;
};

export type SupplyFixContractStateTypes = {
  fetchAll: ApiCallState<SupplyFixContract[]>;
  fetchOne: ApiCallState<SupplyFixContract | {}>;
};

export const SupplyFixContractActionTypes = {
  FETCH_ALL_SUPPLY_FIX_CONTRACT: "FETCH_ALL_SUPPLY_FIX_CONTRACT",
  FETCH_ALL_SUPPLY_FIX_CONTRACT_RESET: "FETCH_ALL_SUPPLY_FIX_CONTRACT_RESET",
  FETCH_ALL_SUPPLY_FIX_CONTRACT_FAILURE:
    "FETCH_ALL_SUPPLY_FIX_CONTRACT_FAILURE",
  FETCH_ALL_SUPPLY_FIX_CONTRACT_SUCCESS:
    "FETCH_ALL_SUPPLY_FIX_CONTRACT_SUCCESS",

  FETCH_ONE_SUPPLY_FIX_CONTRACT: "FETCH_ONE_SUPPLY_FIX_CONTRACT",
  FETCH_ONE_SUPPLY_FIX_CONTRACT_RESET: "FETCH_ONE_SUPPLY_FIX_CONTRACT_RESET",
  FETCH_ONE_SUPPLY_FIX_CONTRACT_FAILURE:
    "FETCH_ONE_SUPPLY_FIX_CONTRACT_FAILURE",
  FETCH_ONE_SUPPLY_FIX_CONTRACT_SUCCESS:
    "FETCH_ONE_SUPPLY_FIX_CONTRACT_SUCCESS",
};
