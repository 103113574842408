import {
  SupportLetterStateTypes,
  SupportLetterActionTypes,
} from "./SupportLetter.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SupportLetterStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SupportLetterReducer = (
  state: SupportLetterStateTypes = INITIAL_STATE,
  action: any
): SupportLetterStateTypes => {
  switch (action.type) {
    case SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SupportLetterActionTypes.FETCH_ALL_SUPPORT_LETTER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SupportLetterActionTypes.FETCH_ONE_SUPPORT_LETTER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SupportLetterReducer;
