import React from "react";
import { Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

export default function EmployeeApplicationSuccess() {
  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="mtop-5 col-lg-7 col-sm-9 mx-auto">
        <div className="col-lg-7 col-sm-9 mx-auto mt-0 pt-1">
          <Card>
            <CheckCircleOutlined
              style={{ color: "green", fontSize: "60px" }}
              className="mx-auto d-block mb-4"
            />
            <h3 className="text-center">Application successful</h3>
            <p
              style={{
                textAlign: "center",
              }}
            >
              You have successfully applied to the job.
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}
