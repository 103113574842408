import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type CastingTestReport = {
  id: number;
  user_id: number;

  project_id: number;
  report_date: string;

  user: User;
  project: Project;
  casting_test_report_items: CastingTestReportItem[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CastingTestReportItem = {
  id: number;
  casting_test_report_id: number;

  date_of_casting: string;
  slump_cone_test: string;

  location_floor: string;
  location_block: string;
  location_axis: string;

  structure_type: string;
  name_of_laboratory: string;

  date_of_test_7: string;
  date_of_test_28: string;

  cube_strength_7: string;
  cube_strength_14: string;
  cube_strength_28: string;

  result_of_test: string;
  action_taken: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CastingTestReportStateTypes = {
  fetchAll: ApiCallState<CastingTestReport[]>;
  fetchOne: ApiCallState<CastingTestReport | {}>;
};

export const CastingTestReportActionTypes = {
  FETCH_ALL_CASTING_TEST_REPORT: "FETCH_ALL_CASTING_TEST_REPORT",
  FETCH_ALL_CASTING_TEST_REPORT_RESET: "FETCH_ALL_CASTING_TEST_REPORT_RESET",
  FETCH_ALL_CASTING_TEST_REPORT_FAILURE:
    "FETCH_ALL_CASTING_TEST_REPORT_FAILURE",
  FETCH_ALL_CASTING_TEST_REPORT_SUCCESS:
    "FETCH_ALL_CASTING_TEST_REPORT_SUCCESS",

  FETCH_ONE_CASTING_TEST_REPORT: "FETCH_ONE_",
  FETCH_ONE_CASTING_TEST_REPORT_RESET: "FETCH_ONE_CASTING_TEST_REPORT_RESET",
  FETCH_ONE_CASTING_TEST_REPORT_FAILURE:
    "FETCH_ONE_CASTING_TEST_REPORT_FAILURE",
  FETCH_ONE_CASTING_TEST_REPORT_SUCCESS:
    "FETCH_ONE_CASTING_TEST_REPORT_SUCCESS",
};
