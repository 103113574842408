import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type SiteHandover= {
  id: number;
  project_id:number;
  date:string;
  location:string;
  document:Document;
  user_id: number;
};

export type SiteHandoverStateTypes = {
  fetchAll: ApiCallState<SiteHandover[]>;
  fetchOne: ApiCallState<SiteHandover | {}>;
};

export const SiteHandoverActionTypes = {
  FETCH_ALL_SITE_HANDOVER: "FETCH_ALL_SITE_HANDOVER",
  FETCH_ALL_SITE_HANDOVER_RESET: "FETCH_ALL_SITE_HANDOVER_RESET",
  FETCH_ALL_SITE_HANDOVER_FAILURE: "FETCH_ALL_SITE_HANDOVER_FAILURE",
  FETCH_ALL_SITE_HANDOVER_SUCCESS: "FETCH_ALL_SITE_HANDOVER_SUCCESS",

  FETCH_ONE_SITE_HANDOVER: "FETCH_ONE_SITE_HANDOVER",
  FETCH_ONE_SITE_HANDOVER_RESET: "FETCH_ONE_SITE_HANDOVER_RESET",
  FETCH_ONE_SITE_HANDOVER_FAILURE: "FETCH_ONE_SITE_HANDOVER_FAILURE",
  FETCH_ONE_SITE_HANDOVER_SUCCESS: "FETCH_ONE_SITE_HANDOVER_SUCCESS",
};
