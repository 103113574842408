import { ApiCallState } from "../Utils";

export type SupplyFixWorkProgress = {
  id: number;
  description: string;
  unit: string;
  material_schedule_date: string;
  material_delivered_date: string;
  schedule_completion_date: string;
  actual_completion_date: string;
  to_date_work_progress: number;
  to_date_payment_amount: number;
  contact_person: number;
  remark: string;
  supply_fix_id: number;
};

export type SupplyFixWorkProgressStateTypes = {
  fetchAll: ApiCallState<SupplyFixWorkProgress[]>;
  fetchOne: ApiCallState<SupplyFixWorkProgress | {}>;
};

export const SupplyFixWorkProgressActionTypes = {
  FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS: "FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS",
  FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_RESET:
    "FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_RESET",
  FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_FAILURE:
    "FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_FAILURE",
  FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_SUCCESS:
    "FETCH_ALL_SUPPLY_FIX_WORK_PROGRESS_SUCCESS",

  FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS: "FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS",
  FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_RESET:
    "FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_RESET",
  FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_FAILURE:
    "FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_FAILURE",
  FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_SUCCESS:
    "FETCH_ONE_SUPPLY_FIX_WORK_PROGRESS_SUCCESS",
};
