import { Boq } from "./../Boq/Boq.type";
import { ApiCallState } from "../Utils";
import { Material } from "../Material/Material.type";
import { Document } from "../Document/Document.type";
import { Project } from "../Project/Project.type";

export type MaterialUsage = {
  id: number;
  boq?: Boq;
  date: string;
  project: Project;
  project_id: number;
  approval: number;
  remark: string;
  material_usage_items: MaterialUsageItem[];
  documents: Document[];
};

export type MaterialUsageItem = {
  id: number;
  material_inventory_id: number;
  material: Material;
  usage_quantity: number;
  usage_unit: string;
  wastage_quantity: number;
  wastage_unit: string;
  site_quantity: number;
  site_quantity_unit: string;
  wastage_reason: string;
  purpose: string;
  material_usage: MaterialUsage;
};

export type MaterialUsageStateTypes = {
  fetchAll: ApiCallState<MaterialUsage[]>;
  fetchOne: ApiCallState<MaterialUsage | {}>;
};

export const MaterialUsageActionTypes = {
  FETCH_ALL_MATERIAL_USAGE: "FETCH_ALL_MATERIAL_USAGE",
  FETCH_ALL_MATERIAL_USAGE_RESET: "FETCH_ALL_MATERIAL_USAGE_RESET",
  FETCH_ALL_MATERIAL_USAGE_FAILURE: "FETCH_ALL_MATERIAL_USAGE_FAILURE",
  FETCH_ALL_MATERIAL_USAGE_SUCCESS: "FETCH_ALL_MATERIAL_USAGE_SUCCESS",

  FETCH_ONE_MATERIAL_USAGE: "FETCH_ONE_MATERIAL_USAGE",
  FETCH_ONE_MATERIAL_USAGE_RESET: "FETCH_ONE_MATERIAL_USAGE_RESET",
  FETCH_ONE_MATERIAL_USAGE_FAILURE: "FETCH_ONE_MATERIAL_USAGE_FAILURE",
  FETCH_ONE_MATERIAL_USAGE_SUCCESS: "FETCH_ONE_MATERIAL_USAGE_SUCCESS",
};
