import axios from "axios";
import { isNil } from "lodash";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CastingTestReportActionTypes } from "./CastingTestReport.type";

export function* fetchAllCastingTestReports(action: any): any {
  try {
    let query = "";

    if (!isNil(action.payload)) {
      let keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/casting-test-report?${query}`
    );
    yield put({
      type: CastingTestReportActionTypes.FETCH_ALL_CASTING_TEST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CastingTestReportActionTypes.FETCH_ALL_CASTING_TEST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCastingTestReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/casting-test-report/${action.payload}`
    );
    yield put({
      type: CastingTestReportActionTypes.FETCH_ONE_CASTING_TEST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CastingTestReportActionTypes.FETCH_ONE_CASTING_TEST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCastingTestReports() {
  yield takeLatest(
    CastingTestReportActionTypes.FETCH_ALL_CASTING_TEST_REPORT,
    fetchAllCastingTestReports
  );
}

export function* watcherFetchOneCastingTestReports() {
  yield takeLatest(
    CastingTestReportActionTypes.FETCH_ONE_CASTING_TEST_REPORT,
    fetchOneCastingTestReports
  );
}
