import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FurnitureMasterListActionTypes } from "./FurnitureMasterList.type";

export function* fetchAllFurnitureMasterLists(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query = Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/furniture-master-list?${query}`
    );
    yield put({
      type: FurnitureMasterListActionTypes.FETCH_ALL_FURNITURE_MASTER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FurnitureMasterListActionTypes.FETCH_ALL_FURNITURE_MASTER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFurnitureMasterLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/furniture-master-list/${action.payload}`
    );
    yield put({
      type: FurnitureMasterListActionTypes.FETCH_ONE_FURNITURE_MASTER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FurnitureMasterListActionTypes.FETCH_ONE_FURNITURE_MASTER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFurnitureMasterLists() {
  yield takeLatest(
    FurnitureMasterListActionTypes.FETCH_ALL_FURNITURE_MASTER_LIST,
    fetchAllFurnitureMasterLists
  );
}

export function* watcherFetchOneFurnitureMasterLists() {
  yield takeLatest(
    FurnitureMasterListActionTypes.FETCH_ONE_FURNITURE_MASTER_LIST,
    fetchOneFurnitureMasterLists
  );
}
